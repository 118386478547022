import KoreanJosa from '@/common/KoreanJosa';
import common from './ko/common';
import main from './ko/main';
import about from './ko/about';
import center from './ko/center';
import accelerating from './ko/accelerating';
import event from './ko/event';
import partners from './ko/partnership';
import networking from './ko/networking';
import faq from './ko/faq';
import users from './ko/users';
import signup from './ko/signup';
import signin from './ko/signin';
import auth from './ko/auth';
import dreamplus from './ko/dreamplus';
import studio from './ko/studio';
import programs from './ko/programs';
import news from './ko/news';
import apply from './ko/apply';

/* eslint-disable max-len */
export default {
  content: {
    common,
    main,
    about,
    center,
    accelerating,
    event,
    partners,
    networking,
    faq,
    users,
    signup,
    signin,
    auth,
    dreamplus,
    studio,
    programs,
    news,
    apply,
  },
  // GNB
  footer: {
    private: '개인정보처리방침',
    agreement: '이용약관',
    faq: 'FAQ',
  },
  gnbMenu: {
    top: {
      about: 'About',
      center: 'Center',
      accelerating: 'Accelerating',
      event: 'Event',
      partnership: 'Partnership',
      oiDirectory: 'OI Directory',
    },
    sign: {
      signin: '로그인',
      signup: '회원가입',
      signout: '로그아웃',
    },
  },
  signUp: {
    title: {
      type: '가입 유형 선택',
      user: '정보 입력',
      agreement: '약관동의',
      auth: '가입 완료',
    },
  },
  sns: {
    naver: '네이버',
    facebook: '페이스북',
    google: 'Google',
    kakao: '카카오',
  },
  networking: {
    player: {
      serviceType: '서비스 분류 선택',
      businessType: '사업 분류 선택',
    },
  },
  commonCode: {
    6: '메인홀',
    7: '멀티룸A',
    8: '멀티룸B',
    9: '멀티룸C',
    10: '멀티룸D',
    11: '멀티룸E',
    ko: 'KOR',
    en: 'ENG',
    ja: 'JPN',
    zh: 'CHN',
    eventStatus01: '행사취소',
    eventStatus02: '행사완료',
    eventStatus03: '행사 중',
    eventStatus04: '신청접수 완료',
    eventStatus05: '신청 접수 중',
    undefined: '',
    MB101: '이메일',
    MB102: '네이버',
    MB103: 'Google',
    MB104: '페이스북',
    MB201: '일반 회원',
    MB202: '입주사 회원 (강남)',
    MB203: '임직원 회원',
    MB204: '파트너 회원',
    MB205: '입주사 회원 (63)',
    MB298: '관리자',
    MB299: '수퍼관리자',
    EV101: '비즈니스/IR',
    EV102: '강의/콘퍼런스',
    EV103: '네트워킹',
    EV104: '기타',
    EV105: '홍보/설명회',
    EV201: '드림플러스 강남 Event Hall',
    EV202: '드림플러스 강남 Multi Room A',
    EV203: '드림플러스 강남 Multi Room B',
    EV204: '드림플러스 강남 Multi Room C',
    // EV205: '드림플러스 63 Event Hall',
    EV205: '드림플러스 강남 Multi Room D',
    EV206: '드림플러스 강남 Multi Room E',
    EV209: '기타',
    EV301: '사이트 내부',
    EV302: '외부 사이트',
    EV501: '일반회원',
    EV502: '입주사(강남)',
    EV503: '한화회원',
    EV504: '파트너회원',
    EV505: '입주사(63)',
    EV506: '회원 전체',
    EV507: '회원전체 & 비회원 포함',
    EV508: '비회원',
    EV509: '기타회원',
    EV601: '행사완료',
    EV602: '행사 중',
    EV603: '행사일',
    EV604: '신청마감',
    EV605: '신청 접수 중',
    EV606: '신청 접수 전',
    EV609: '행사취소',
    PL101: '스타트업',
    // PL102: `액셀러레이터`,
    PL102: '액셀러레이터/벤처캐피탈',
    // PL103: `인큐베이터`,
    // PL104: `컴퍼니 빌더`,
    // PL105: `벤처캐피탈`,
    // PL106: `기업 벤처캐피탈`,
    // PL107: `사모투자회사`,
    // PL108: `엔젤투자클럽`,
    // PL109: `개인투자조합`,
    // PL110: `개인투자자(엔젤)`,
    // PL111: `대기업`,
    PL111: '기업',
    // PL112: `중소기업`,
    // PL113: `특수목적기업`,
    // PL114: `개인사업자`,
    // PL115: `공공기관`,
    PL115: '공공기관/협회',
    // PL116: `민간기관`,
    // PL117: `지원기관`,
    // PL118: `비영리단체`,
    // PL119: `협회/재단`,
    // PL120: `정부단체`,
    // PL121: `교육단체`,
    PL121: '교육기관',
    // PL122: `드림플러스 입주사`,
    // PL123: `기타`,
    PL201: '라이프스타일',
    PL202: '모바일/APP',
    PL203: 'IOT',
    PL204: 'O2O',
    PL205: '블록체인',
    PL206: '교육',
    PL207: '헬스케어',
    PL208: '의료/바이오',
    PL209: '뷰티/패션',
    PL210: '환경/에너지',
    PL211: '미디어/엔터테인먼트',
    PL212: '게임/퍼블리싱',
    PL213: '영상/컨텐츠',
    PL214: '광고/마케팅/홍보',
    PL215: '숙박/음식',
    PL216: '여행/레저',
    PL217: '유통/물류',
    PL218: '화학/중공업',
    PL219: '자동차/드론',
    PL220: '도서/출판',
    PL221: '디자인/인테리어',
    PL222: '쇼핑/e커머스',
    PL223: '결제/보안',
    PL224: '반려동물/취미',
    PL225: '예술/스포츠',
    PL226: '육아/웨딩',
    PL227: 'VR/AR',
    PL228: '정부/공공',
    PL229: '부동산/건설',
    PL230: '농/수산/임/광공업',
    PL231: 'AI/자율주행',
    PL232: '전자/전기/기계',
    PL233: '법률/특허/회계/세무',
    PL234: '데이터/통계',
    PL235: '제조/하드웨어',
    PL236: '공유경제/렌탈',
    PL237: '금융/핀테크',
    PL238: '교통/통신',
    PL239: '소프트웨어/개발',
    PL240: '투자',
    PL241: 'CSR/CSV',
    PL242: '3D프린터',
    PL243: '이벤트/행사',
    PL244: 'NGO/NPO',
    PL245: '스타트업 지원',
    PL246: '경영컨설팅',
    PL301: '스타트업',
    PL302: '액셀러레이터',
    PL303: '투자자',
    PL304: '기업/기관',
    PL305: '정부단체',
    PL306: '교육단체',
    PL501: '승인완료',
    PL502: '임시저장',
    PL503: '등록요청',
    PL504: '심사중',
    PL505: '승인보류',
    PL601: '강남',
    PL602: '63',
    PL603: '졸업사',
    PL609: '해당없음',
    PL701: 'ACC',
    PL702: 'ACC',
    PL709: '해당없음',
    PL801: '1~10 명',
    PL802: '11~50 명',
    PL803: '51~100 명',
    PL804: '101~300 명',
    PL805: '301~500 명',
    PL806: '501~1000 명',
    PL807: '1001 명 이상',
    PL1001: '컨택포인트',
    PL1002: '공동대표',
    PL1003: '컨택포인트',
    PL1004: '담당자',
    PL1101: 'Seed(Pre-A)',
    PL1102: 'Series A',
    PL1103: 'Series B',
    PL1104: 'Series C',
    PL1105: 'Series C+',
    PL1106: 'IPO',
    PL1107: 'M&A',
    PL1108: 'ICO',
    PL1109: 'Others',
    BZ101: '사업협력',
    BZ102: '투자',
    BZ103: '해외진출',
    BZ104: '서비스/전략제휴',
    BZ199: '[관리자만 보기 게시물]',
    CT101: '강남',
    CT102: '63',
    CT201: '메인홀',
    CT202: '멀티룸A',
    CT203: '멀티룸B',
    CT204: '멀티룸C',
    CT203_4: '멀티룸 B+C',
    CT205: '멀티룸D',
    CT206: '멀티룸E',
    CT205_6: '멀티룸 D+E',
    CT207: '멀티룸D',
    CT208: '멀티룸E',
    CT301: '테이블+의자 144석',
    CT302: '의자 200석',
    CT303: '기타요청',
    CT401: '대관 승인',
    CT402: '대관 신청',
    CT403: '대관 취소',
    CT404: '대관 거절',
    AC290: '지원 완료',
    AC291: '지원 취소',
    AC292: '합격',
    AC293: '불합격',
    AC299: '임시 저장',
    AC300: '모집중지',
    AC301: '모집전',
    AC302: '모집중',
    AC303: '공모 마지막일',
    AC304: '모집종료',
    ZA001: '초기화',
    ZA002: '확인',
    ZA003: '취소'
  },
  // Member
  customer: {
  },
  // for custom alert message
  alertMessage: {
    logout: '로그아웃 되었습니다.',
    sentEmail: '메일을 발송하였습니다.',
    sentMessage: '메시지가 발송되었습니다.',
    deleted: '삭제되었습니다',
    updated: '수정되었습니다.',
    saved: '저장되었습니다.',
    canceled: '취소되었습니다.',
    savedTemporary: '임시저장 되었습니다.',
    emptiedReply: '댓글을 입력해 주세요',
    registered: '등록되었습니다.',
    deletedUser: '회원탈퇴 완료되었습니다.',
    updatedUser: '회원정보 수정이 완료되었습니다.',
    approving: '승인대기 중입니다.',
    applicatedEvent: '이벤트 참여 신청이<br>완료되었습니다.',
    faildEvent: '모집정원 초과로 이벤트 참여 신청이<br>실패되었습니다.',
    beforeApplicatedEvent: '이미 신청하신 이벤트 입니다.',
    canceldEvent: '신청하신 이벤트가<br>취소 되었습니다.',
    resetPassword: '비밀번호 재설정 안내메일을 발송하였습니다.<br>메일을 확인해 주세요.',
    deletedEmptyMessage: '삭제할 메시지를<br>선택해 주세요.',
    invalidVenueHour: '대관신청은 최소2시간 이상,<br>1시간 단위이어야 합니다.',
    emptiedVenue: '원하는 시간을<br>드래그해서 선택해 주세요.',
    invalidVenueRoom: '멀티룸 대관은 메인홀과 함께<br>대관할 경우에만 가능하며<br>대관시간은 메인홀 대관시간과<br>동일해야 합니다.',
    // invalidVenueDiffHour: `메인홀과 멀티룸을 동반예약할 경우<br>대관시간은 동일한 시간만 신청 가능합니다.`,
    invalidVenueDiffHour: '동반예약할 경우<br>대관시간은 동일한 시간만 신청 가능합니다.',
    invalidReApplyVenue: '메인홀과 멀티룸의 대관은<br>같은 시간이어야 합니다.',
    lectureApplication: '수강신청 접수되었습니다.',
    invalidVenueWeekendRoom: '토요일 대관시 멀티룸 A,B,C는<br/>메인홀과 함께 대관할 경우에만 가능하며<br>대관시간은 메인홀 대관시간과<br>동일해야 합니다.',
    invalidVenueWeekendRole1: '토요일 대관시 멀티룸 E는 멀티룸 D와<br/>동일 시간 동반 대관하는 경우에만<br/>대관이 가능합니다.',
    invalidVenueWeekendRole2: '토요일 대관시 멀티룸 D는 멀티룸 E와<br/>동일 시간 동반 대관하는 경우에만<br/>대관이 가능합니다.',
    invalidVenueRole1: '멀티룸 E는 메인홀 또는 멀티룸 D와<br/>동일 시간 동반 대관하는 경우에만<br/>대관이 가능합니다.',
    invalidVenueRole2: '멀티룸 D는 메인홀 또는 멀티룸 E와<br/>동일 시간 동반 대관하는 경우에만<br/>대관이 가능합니다.',
    invalidVenueEtc: '예기치 못한 오류발생 하였습니다.<br>관리자에게 문의 주시기 바랍니다.',
    invalidVenueMatchTime: '메인홀 대관신청시 기존신청하셨던 멀티룸과 동일한 시간으로 선택하셔야 합니다.',
    invalidVenueErr1: '메인홀과 같이 예약하셔야 합니다만 다른 예약자가 예약중이라 예약을 하실 수 없습니다.',
    invalidVenueErr2: '멀티룸D와 같이 예약하셔야 합니다만 다른 예약자가 예약중이라 예약을 하실 수 없습니다.',
    invalidVenueErr3: '멀티룸E와 같이 예약하셔야 합니다만 다른 예약자가 예약중이라 예약을 하실 수 없습니다.',
    invalidEventHallErr1: '예약 시간 전후 30분<br/>정리시간이 필요합니다.',
  },
  confirmMessage: {
    canceled: '취소하시겠습니까?',
    requiredLogin: '로그인이 필요합니다.<br>로그인 하시겠습니까?',
    deletedMessage: '해당 메시지를<br> 삭제하시겠습니까?',
    deletedReply: '댓글을<br>삭제하시겠습니까?',
    deletedPosts: '해당 게시글을<br> 삭제하시겠습니까?',
    deletedPlayer: '해당 플레이어를<br> 삭제하시겠습니까?',
    deletedUser: '회원탈퇴 시,<br>서비스의 모든 이용정보 및 기록도<br>함께 삭제 됩니다.<br>탈퇴하시겠습니까?',
    canceledProgram: '프로그램 지원을<br> 취소하시겠습니까?',
    canceledBizOpp: '요청문의 등록을<br> 취소하시겠습니까?',
    lectureApplication: '수강신청 하시겠습니까?',
  },
  // for custom error message
  errorMessage: {
    invalidLogin: '로그인 할 수 없습니다. E-mail 주소와 비밀번호를 확인해 주세요',
    invalidEmail: '로그인 할 수 없습니다. E-mail 주소를 확인해 주세요. 페이스북 연락처 로그인은 불가능 합니다.',
    invalidPassword: '비밀번호가 맞지 않습니다.',
    DuplicatedPassword: '이전 비밀번호와 동일한 비밀번호는 사용 불가입니다.',
    notFoundEmail: '해당 SNS 계정과 일치하는<br/>회원 정보를 찾을수 없습니다.<br/>회원가입을 먼저 진행해주세요. ',
    usedEmail: '드림플러스 강남 입주 시 사용 E-mail 입니다.<br/>다른 SNS  채널로 가입 부탁 드립니다.',
    code999: '서버에러!!! 개발자에 문의하시기 바랍니다.',
    code625: '인증정보 갱신을 위한<br>재로그인이 필요합니다.',
  },
  /*
   * VeeValidate
   * - Localization : https://logaretm.github.io/vee-validate/guide/localization.html
   * - Messages as Functions : https://logaretm.github.io/vee-validate/guide/basics.html#messages
   */
  validations: {
    code: 'ko',
    names: {
      email: 'E-mail',
    },
    messages: {
      email: '{_field_} 주소가 올바르지 않습니다.',
      phone: '{_field_}가 올바르지 않습니다.',
      required: (fieldName) => {
        const fieldNameAndJosa = KoreanJosa.getWordWithJosa(
          fieldName,
          KoreanJosa.EulReul,
        );
        return `${fieldNameAndJosa} 입력해 주세요.`;
      },
      fieldRequired: (fieldName) => {
        const fieldNameAndJosa = KoreanJosa.getWordWithJosa(
          fieldName,
          KoreanJosa.EunNeun,
        );
        return `${fieldNameAndJosa} 필수 입력 사항입니다.`;
      },
      size: (fieldName) => `${fieldName} 파일 용량을 확인해주세요.`,
    },
    fields: {
      regexPassword: {
        regex: '비밀번호 조합과 글자수를 확인해 주세요.',
        required: '비밀번호를 입력해 주세요.',
      },
      regexUserName: {
        regex: '한글, 영문, 숫자만 입력 가능합니다.',
        required: '닉네임을 입력해 주세요.',
      },
      regexPhone: {
        regex: '전화번호를 정확히 입력해주세요.',
        required: '전화번호를 입력해 주세요.',
      },
    },
  },
};
