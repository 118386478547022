import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import {
  getFingerprint, getClientData, TOKEN_COOKIE_NAME, container, append,
} from '@/common/helperUtils';
import { APIs, getPortalApiClient } from '@/common/portalApi';
import axios from 'axios';
import applyRouter from './applyRouter';
import centerRouter from './centerRouter';
import eventRouter from './eventRouter';
import reservationRouter from './reservationRouter';
import newsRouter from './newsRouter';
import contentsRouter from './contentsRouter';
import lettersRouter from './letterRouter';
import programsRouter from './programsRouter';
import authRouter from './authRouter';
import redirectRouter from './redirectRouter';
import routeTypes from './routeTypes';
import contestRouter from './contestRouter';

Vue.use(VueRouter);

const appendName = append;
const noindexMetaInfo = [
  { property: "robots", content: "noindex", vmid: "robots" },
  { property: "googlebot", content: "noindex", vmid: "googlebot" }
];

const routes = [
  /** DREAMPLUS 개편 */
  {
    path: '/',
    name: 'Home',
    alias: '/dreamplus',
    component: () => import(`@/views/dreamplus/${appendName}DreamPlus.vue`),
    meta: {
      layout: container,
      title: 'DREAMPLUS',
    },
  },
  {
    path: '/studio',
    name: 'Studio',
    component: () => import(`@/views/dreamplus/${appendName}Studio.vue`),
    meta: {
      layout: container,
      title: 'STUDIO',
      metaInfo: noindexMetaInfo
    },
  },
  /** DREAMPLUS - Who We Are (메인) 
   *  2024. 7. 19. 비활성화
   */
  // {
  //   path: '/who-we-are',
  //   name: 'who-we-are',
  //   component: () => import(`@/views/dreamplus/${appendName}WhoWeAre.vue`),
  //   meta: {
  //     layout: container,
  //     title: 'Who We Are',
  //   },
  // },
  /** DREAMPLUS - What We Do 
   *  2024. 7. 19. 비활성화
   */
  // {
  //   path: '/what-we-do',
  //   component: () => import(`@/views/dreamplus/${appendName}WhatWeDo.vue`),
  //   meta: {
  //     layout: container,
  //     title: 'What We Do',
  //     metaInfo: noindexMetaInfo
  //   },
  // },
  /** DREAMPLUS - Our Partners 
   *  2024. 7. 19. 비활성화
   */
  // {
  //   path: '/our-partners',
  //   component: () => import(`@/views/dreamplus/${appendName}OurPartners.vue`),
  //   meta: {
  //     layout: container,
  //     title: 'Our Partners',
  //   },
  // },

  /** CENTER
   * 2024. 7. 18. 비활성화
   */
  // {
  //   path: '/center',
  //   component: () => import('@/views/center/Center.vue'),
  //   children: [
  //     ...centerRouter,
  //   ],
  // },

  /** PROGRAMS */
  {
    path: '/programs',
    component: () => import(`@/views/programs/${appendName}Programs.vue`),
    meta: {
      layout: container,
      title: 'Programs',
    },
    children: [
      ...programsRouter,
    ],
  },

  /** APPLY 
   * 2024. 7. 18. 비활성화
   */
  // {
  //   path: '/startup',
  //   component: () => import(`@/views/programs/${appendName}Programs.vue`),
  //   meta: {
  //     layout: container,
  //     title: 'Apply',
  //   },
  //   children: [
  //     ...applyRouter,
  //   ],
  // },
  /** EVENT & NEWS - Event
   *  2024. 7. 17. 비활성화
   */
  // {
  //   path: '/event',
  //   component: () => import(`@/views/event/${appendName}Event.vue`),
  //   children: [
  //     ...eventRouter,
  //   ],
  // },
  /** RESERVATION - Event  */
  {
    path: '/reservation',
    component: () => import(`@/views/reservation/${appendName}Reservation.vue`),
    children: [
      ...reservationRouter,
    ],
  },
  /** EVENT & NEWS - News  */
  // 2024. 7. 19. 비활성화
  // {
  //   path: '/news',
  //   component: () => import(`@/views/news/${appendName}News.vue`),
  //   children: [
  //     ...newsRouter,
  //   ],
  // },
  /** CONTENTS - CONTENTS  */
  {
    path: '/contents',
    component: () => import(`@/views/contents/${appendName}Contents.vue`),
    children: [
      ...contentsRouter,
    ],
  },

  /** LETTER  */
  {
    path: '/letter',
    component: () => import(`@/views/letter/${appendName}Letter.vue`),
    children: [
      ...lettersRouter,
    ],
  },
  // 공모전 추가 230406
  {
     path: '/contest',
     component: () => import(`@/views/contest/${appendName}Contest.vue`),
     children: [
       ...contestRouter,
     ],
  },
  /** 이용약관  */
  {
    path: '/terms',
    component: () => import(`@/views/legal/${appendName}Terms.vue`),
    meta: {
      layout: container,
      title: '이용약관',
      headertype: 'sub',
      metaInfo: noindexMetaInfo
    },
  },

  /** 개인정보 처리방침  */
  {
    path: '/privacy',
    component: () => import(`@/views/legal/${appendName}Privacy.vue`),
    meta: {
      layout: container,
      title: '개인정보 처리방침',
      headertype: 'sub',
      metaInfo: noindexMetaInfo
    },
  },

  /** KAKAO 처리방침  */
  {
    path: '/oauth/kakao',
    component: () => import(`@/views/auth/KakaoLoginCallback.vue`)
  },

  /** Auth */
  ...authRouter,

  /** Redirect */
  ...redirectRouter,

  /**
   * 404
   * @see https://router.vuejs.org/guide/essentials/dynamic-matching.html#catch-all-404-not-found-route
   * @see https://github.com/vuejs/vue-router/issues/724
   */
  {
    path: routeTypes.ROUTE_PATH_NOT_FOUND,
    alias: '*',
    component: () => import(/* webpackChunkName: "error" */ `../views/error/${appendName}Error404.vue`),
    meta: {
      metaInfo: noindexMetaInfo
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  /**
   * Scroll Behavior
   * @see {@link https://router.vuejs.org/guide/advanced/scroll-behavior.html}
   * @see {@link https://github.com/vuejs/vue-router/blob/dev/examples/scroll-behavior/app.js}
   */
  // scrollBehavior(to) { // to, from, savedPosition
  //   // 리프레시할 때 무조건 최상단으로 가도록 주석 처리
  //   // if (savedPosition) {
  //   //   return savedPosition;
  //   // }
  //   const position = {};

  //   if (to.hash) {
  //     position.selector = to.hash;
  //   } else {
  //     position.x = 0;
  //     position.y = 0;
  //   }

  //   return position;
  // },
  base: process.env.BASE_URL,
  routes,
});

// const headTitlePrefix = '';
// const headTitlePostfix = ' | DREAMPLUS';

/**
 * (beforeEach) 로그인 필요여부 체크
 * @see {@link https://router.vuejs.org/guide/advanced/meta.html}
 * @see {@link https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards}
 */
router.beforeEach(async (to, from, next) => {
  if (to.query.redirectUrl) {
    return next({
      path: to.query.redirectUrl
    })
  }
  if (appendName === 'Mobile') {
    store.commit('setPagestep', 1);
  }

  // 토큰 조회
  const token = Vue.$cookies.get(TOKEN_COOKIE_NAME);
  // 로그인 정보 불필요
  if (!token && !to.matched.some((r) => r.meta.requiresAuth)) {
    return next();
  }

  // 사용자 정보 조회
  if (token) {
    const { userInfo } = store.state;
      if (!userInfo) {
        // 사용자 정보 조회
        const res = await axios({
          method: 'POST',
          url: `/api/authentication`,
          headers: {
            Authorization: token,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
          },
        })
        if (res.data.result) {
          store.commit('setUserInfo', res.data.data);
        } else if (to.matched.some((r) => r.meta.requiresAuth)) {
          return next({
            name: routeTypes.ROUTE_NAME_SIGNIN,
            query: { redirectFrom: to.fullPath },
          });   
        }
      }
  } else {
    return next({
      name: routeTypes.ROUTE_NAME_SIGNIN,
      query: { redirectFrom: to.fullPath },
    });
  }

  store.dispatch('updateLogoutTimeout', null)
  return next();
});

/**
 * (beforeEach) meta 설정으로 head title 업데이트
 * @see {@link https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head}
 * @see {@link https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards}
 */
router.beforeEach(async (to, from, next) => {
  // const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);
  // if (nearestWithTitle) document.title = `${headTitlePrefix}${nearestWithTitle.meta.title}${headTitlePostfix}`;
  document.title = '드림플러스 | DREAMPLUS';

  return next();
});

const applyReg = new RegExp(/^\/programs\/application\/((?:\d+))\/apply/);
/**
 * 지원하기 진입 페이지 필터링
 */
 router.beforeEach(async (to, from, next) => {
  if (to.path.match(applyReg)) {
    if (!from.path.match(applyReg) && from.path !== '/') {
      localStorage.setItem('applyPreviousUrl', JSON.stringify({
        path: from.path,
        params: from.params,
      }));
    }
  }
  return next();
});

/**
 * (afterEach) 페이지 전환시 로깅
 * @see {@link https://router.vuejs.org/guide/advanced/navigation-guards.html#global-after-hooks}
 */
router.afterEach(async (to) => {
  // const clientData = await getClientData();
  // const fingerprint = await getFingerprint();
  // const token = Vue.$cookies.get(TOKEN_COOKIE_NAME);
  // const apiClient = getPortalApiClient(
  //   token,
  //   fingerprint,
  //   () => {},
  // );

  // apiClient({
  //   url: APIs.REGIST_LOG,
  //   headers: {
  //     fingerprint,
  //     subpath: to.path,
  //     clientip: clientData.ip,
  //   },
  //   data: {
  //     url: to.path,
  //     clientip: clientData.ip,
  //   },
  // });
});

router.onError((error) => {
  if (error.name === 'ChunkLoadError') {
    window.location.reload()
  }
})

export default router;
