/* eslint-disable max-len */
export default {
  // 폴더 구조랑 비슷하게...
  alert: {
    txt01: '페이지 로딩으로 필수값이 초기화되었습니다.<br> 초기화면으로 돌아갑니다.',
    txt02: '임시저장되었습니다.',
    txt03: '데이터 저장 중 오류가 발생했습니다 ',
    txt04: '지원을 취소하시겠습니까?',
    txt05: '업체명은 필수입력 항목입니다.',
    txt06: '업체명 영문은 필수입력 항목입니다.',
    txt07: '설립연도는 필수입력 항목입니다.',
    txt08: '설립월은 필수입력 항목입니다.',
    txt09: '현재 직원 수는 필수입력 항목입니다.',
    txt10: '직원 추가 계획은 필수입력 항목입니다.',
    txt11: '대표자는 필수입력 항목입니다.',
    txt12: '멤버이름은 필수입력 항목입니다.',
    txt13: '근무형태는 필수입력 항목입니다.',
    txt14: '직책은 필수입력 항목입니다.',
    txt15: '주요경력/학력은 필수입력 항목입니다.',
    txt16: '창업프로그램 시작일이 없습니다.',
    txt17: '창업프로그램 종료일이 없습니다.',
    txt18: '종료일이 시작일보다 빠를 수 없습니다.',
    txt19: '서비스명은 필수입력 항목입니다.',
    txt20: '서비스소개은 필수입력 항목입니다.',
    txt21: '서비스차별점은 필수입력 항목입니다.',
    txt22: '사업자 등록증 파일 첨부는 필수 항목입니다.',
    txt23: '주주명부 파일 첨부는 필수 항목입니다.',
    txt24: '등기부등본 파일 첨부는 필수 항목입니다.',
    txt25: '벤처기업등록증 파일 첨부는 필수 항목입니다.',
    txt26: '지적재산권 사본 파일 첨부는 필수 항목입니다.',
    txt27: '기타 파일 첨부는 필수 항목입니다.',
    txt28: '취소되었습니다.',
    txt29: '새로작성하실경우 임시저장한 내용이 삭제됩니다.<br>계속하시겠습니까?',
    txt30: '취소되었습니다.',
    txt31: '지원영역은 필수 항목입니다.',
    txt32: '신청구분은 필수 항목입니다.',
    txt33: '맴버구성은 필수 항목입니다.',
    txt34: '맴버구성의 팀 인원은 필수입력 항목입니다.',
    txt35: '휴대폰 번호는 필수입력 항목입니다.',
    txt36: '신청기간이 아닙니다.',
    txt37: '투자유치 현황은 필수 항목입니다.',
    txt38: '지원경로는 필수 항목입니다.',
    txt39: '대표자 이메일은 필수 항목입니다.',
    txt39_1: '대표자 이메일 정보가 옳바르지 않습니다.',
    txt39_2: '이메일 정보가 옳바르지 않습니다.',
    txt40: '대표자 최종학력은 필수 항목입니다.',
    txt41: '대표자 주요이력은 필수 항목입니다.',
    txt42: '연락처는 필수 항목입니다.',
    txt43: '이메일은 필수 항목입니다.',
    txt44: '지원동기 및 포부는 필수 항목입니다.',
    txt45: '이랜드리테일과의 제휴 아이디어나 제안은 필수 항목입니다.',
    txt46: '이용동의는 필수입니다.',
    txt47: '\'{0}\'을 입력하세요',
    txt48: '\'{0}\'을 선택하세요',
  },
  Common: {
    txt01: '지원대상',
    txt02: '지원혜택',
    txt03: '액셀러레이팅 프로그램',
    txt04: '사업화 지원',
    txt05: '투자연계/해외 사업화 지원',
    txt06: '투자',
    txt07: '네트워크',
    txt08: '지원절차',
    txt09: '※ 세부일정은 별도 공지됩니다.',
    txt10: '서류접수',
    txt11: '1차 서류심사',
    txt12: '2차 대면심사',
    txt13: '합격자 통보 및 발표',
    txt14: '대표사례',
    txt15: '드림플러스의 액셀러레이팅을 받길 원하거나 액셀러레이팅 관련 문의사항이 있으면 <a href="mailto:accelerating@dreamplus.asia">accelerating@dreamplus.asia</a>로 회사소개서(사업계획서)와 함께 보내 주세요.',
    txt16: '신청방법',
    txt17: '프로그램',
    txt18: '입주사 혜택',
    txt19: '이벤트 프로그램',
    txt20: '모집기간',
    txt21: '합격발표',
    txt22: '자세히 보기',
    txt23: '해당 항목은 입력할 내용이 없습니다.',
    txt24: '다음 단계로 진행해주세요.',
    txt25: '지원내용 보기',
    txt26: '현재 \'{0}\'에 지원이 불가능합니다.',
    txt27: '\'{0}\'에 지원하시겠습니까?',
    txt28: '입력한 모든 정보가 삭제됩니다.<br/>지원서 등록을 취소하시겠습니까?',
    txt29: '상시모집',
    txt30: '신청하기',
    txt31: '상세보기',
    txt32: '드림플러스는 Open Innovation을 시작하려는 모든 기업과 스타트업, 파트너사와 함께',
    txt33: '선순환되는 스타트업 생태계 구축에 선도적 역할을 하고자 합니다.',
    txt34: '바로 지금, 드림플러스의 Accelerating Program과 함께 꿈을 향한 모험과 도전을 시작할 때입니다.',
    txt35: '모집전 \'{0}\'',
    txt36: '모집중',
    txt37: '모집종료',
    txt38: '모집중지',
    txt39: '공모 지원하기',
    txt40: '현재 공모 중인 프로그램이 없습니다',
  },
  Fintech: {
    txt01: '핀테크 및 보험업, 금융업과 시너지를 낼 수 있는 스타트업에게<br/>한화금융계열사와의 사업화 연계 및 투자기회를 지원합니다.',
    txt01_1: '핀테크 및 보험업, 금융업과 시너지를 낼 수 있는 스타트업에게 한화금융계열사와의 사업화 연계 및 투자기회를 지원합니다.',
    txt02: '핀테크',
    txt03: '<드림플러스 핀테크 액셀러레이팅 프로그램>은 핀테크 및 보험업,<br/> 금융업과 시너지를 낼 수 있는 여러 분야의 스타트업들에게 한화금융계열사와의<br/> 사업화 연계를 지원하는 프로그램 입니다. 선정된 스타트업은 한화금융<br/> 계열사와의 <span class="point">사업연계, 사업성장을 위한 투자연계, 네트워킹, 해외진출지원 및<br/> 드림플러스 인프라(사무공간, 미디어센터 등)의 사용기회</span>를<br/> 제공받을 수 있습니다.',
    txt03_1: '<드림플러스 핀테크 액셀러레이팅 프로그램>은 핀테크 및 보험업, 금융업과 시너지를 낼 수 있는 여러 분야의 스타트업들에게 한화금융계열사와의 사업화 연계를 지원하는 프로그램 입니다. 선정된 스타트업은 한화금융 계열사와의 <span class="point">사업연계, 사업성장을 위한 투자연계, 네트워킹, 해외진출지원 및 드림플러스 인프라(사무공간, 미디어센터 등)의 사용기회</span>를 제공받을 수 있습니다.',
    txt04: '핀테크 스타트업',
    txt05: '빅데이터, AI, 블록체인, 간편결제, 증권정보 등',
    txt06: '보험업과 시너지를 낼 수 있는 기술/서비스를 보유한 스타트업',
    txt06_1: '디지털헬스케어, 웰니스 및 금융생활 혁신기업',
    txt07: '계열사 연계',
    txt08: '한화금융계열사 담당자 매칭',
    txt09: '정례미팅을 통한 사업화기회 발굴',
    txt10: '테스트 베드',
    txt11: '해당서비스 한화계열사 대상 파일럿 진행 검토',
    txt12: '인프라',
    txt13: '드림플러스 63 사무공간 선별적 지원',
    txt14: '드림플러스 63 성장지원 프로그램 참여기회 제공',
    txt15: '한화금융계열사 및 외부 VC 네트워크를 통한 투자연계 지원',
    txt16: '드림플러스의 글로벌 진출지원 프로그램인 GEP(Global Expansion Program)을 통한 해외 진출 기회제공',
    txt17: '2014년 설립된 콰라는 ',
    txt18: '금융 인공지능(AI) 딥러닝 기술로 과거 금융환경을 분석하고 미래를<br/> 예측하는 솔루션을 개발/제공하는 로보어드바이저 핀테크 기업입니다.',
    txt19: '콰라는 영국과 한국 최고의 VC로부터 투자를 유치했고,<br/> 아시아 금융사들과 협업 하고 있는<br/> Asia Leading Deep Learning Robo-Advisor 스타트업 입니다.',
    txt18_1: '금융 인공지능(AI) 딥러닝 기술로 과거 금융환경을 분석하고 미래를 예측하는 솔루션을 개발/제공하는 로보어드바이저 핀테크 기업입니다.',
    txt19_1: '콰라는 영국과 한국 최고의 VC로부터 투자를 유치했고, 아시아 금융사들과 협업 하고 있는 Asia Leading Deep Learning Robo-Advisor 스타트업 입니다.',
    txt20: '2014년 설립된 지속가능발전소는',
    txt21: '빅데이터/머신러닝기반 스타트업으로, 기업의 비재무(Non-Financial)<br/> 리스크 분석 로보 애널리스트 ‘후스굿(Who‘s Good)’을 서비스하고 있습니다.',
    txt22: '지속가능발전소는 ESG(환경,사회,지배구조)와 관련된<br/> 기업의 비재무 정보를 AI 기반으로 분석해 신뢰할 수 있는<br/> 리포트를 제공합니다.',
    txt21_1: '빅데이터/머신러닝기반 스타트업으로, 기업의 비재무(Non-Financial) 리스크 분석 로보 애널리스트 ‘후스굿(Who‘s Good)’을 서비스하고 있습니다.',
    txt22_1: '지속가능발전소는 ESG(환경,사회,지배구조)와 관련된 기업의 비재무 정보를 AI 기반으로 분석해 신뢰할 수 있는 리포트를 제공합니다.',
    txt23: '투자연계/<br/>해외 사업화 지원',
    txt24: '해당서비스 한화계열사 대상<br/>파일럿 진행 검토',
    txt25: '드림플러스의 글로벌 진출지원 프로그램인<br/>GEP(Global Expansion Program)을 통한 해외 진출 기회제공',
    txt26: `키튼플래닛은 증강현실을 통한 양치 교육 서비스를하는 기업입니다. <br/><strong>한화생명과 협업하여  브러쉬몬스터와 결합한 건강증진형 보험상품  <br/>'라이프플러스 어린이보험'을 출시했습니다.</strong> 보험업계 최초로 증강현실
    앱과 디지털기기를 활용한 신개념 건강증진형<br/> 보험상품으로 화제가 되었습니다.`,
    txt27: `마일로(현 일상이상주의)는 취미/활동 기반 클래스를 예약 구독할 수 있는<br/> 플랫폼입니다. 다양한 정보를 얻을 수도 있고, 각 클래스를 별도로 회원권을<br/>구매할 필요없이 통합 구매할 수도 있어 사용자에게 편의성을 제공합니다.<br/>
    <strong>한화그룹 임직원 복지 상품 연간 계약을 체결하는 등의 협업을 이뤄가고<br/> 있습니다.</strong>`,
  },
  Lifestyle: {
    Health: {
      txt01: '전문 파트너 및 멘토와 함께 다양한 라이프스타일 영역의 창ㆍ취업을 지원합니다.',
      txt02: '디지털 헬스케어 / 웰니스',
      txt03: '에듀케이션',
      txt04: 'F&B',
      txt05: '콘텐츠 크리에이터',
      txt06: '패션&굿즈 크리에이터',
      txt07: `<드림플러스 헬스케어 액셀러레이팅 프로그램>은 디지털 헬스케어 분야 및 헬스케어와 접목가능한 분야의 다양한 스타트업들과 한화계열사 간 사업화 연계를 지원하고 있습니다.<br>
      전문 액셀러레이터 <신패스DHN>과 함께 운영하며, 선정된 스타트업에게는 6개월간 전담 액셀러레이터 배정을 통해 <span class="point">헬스케어 관련 교육 프로그램과 업계 전문가 멘토링이 지원</span>됩니다.`,
      txt08: '디지털 헬스케어 관련 서비스 스타트업',
      txt09: 'Wellness & Fitness 관련 스타트업(B2C 분야 포함)',
      txt10: '헬스케어와 접목 가능한 기술/서비스를 가진 스타트업',
      txt11: '인공지능, 스마트의료, 의사대상 서비스 등',
      txt12: '6개월간 전문 액셀러레이터 밀착 지원',
      txt13: '헬스케어 스타트업 관련 교육 프로그램 :  특화교육, 관련규제, 투자유치, 산업동향 등',
      txt14: '헬스케어 전문가 멘토링 지원 : 신패스 DHN, 뉴플라이트, 현직 의사 자문단 등',
      txt15: '드림플러스 강남 사무공간 선별적 지원(기본 6개월)',
      txt16: '드림플러스 강남의 디지털 미디어 센터 활용 기회 제공',
      txt17: '그룹 인프라 Test Bed 활용기회 제공',
      txt18: '법무·세무·회계·홍보 서비스',
      txt19: '드림플러스 GEP(Global Expansion Program) 통한 글로벌 진출 기회 제공',
      txt20: '전용 펀드를 통한 투자검토',
      txt21: '그룹 내/외부 VC 연계 지원',
      txt22: '데모데이',
      txt23: '그룹 혁신사업 비즈니스 협력 검토',
      txt24: '디지털 콘텐츠 스타트업',
      txt25: '콘텐츠 크리에이터',
    },
    Education: {
      txt01: `<드림플러스 에듀케이션 액셀러레이팅 프로그램>은 에듀테크를 포함한 다양한 교육 분야의 스타트업을 지원하고 있습니다.<br> 국내 최대규모의 교육문화기업인 <교원그룹>과 함께 운영하며 선정된 스타트업에게는 6개월간 담당 액셀러레이터 배정을 통해 사업협력 프로젝트
      진행은 물론 <span class="point">법무 · 홍보 · 해외진출 및 국내외 VC를 통한 투자 유치를 지원합니다.</span>`,
      txt02: '교육 전 분야 및 교육분야 연계 가능 스타트업',
      txt03: '다양성 존중, 경험과 체험중심의 온/오프라인 교육 콘텐츠 스타트업',
      txt04: 'VR/AR, AI, Robot, Big Data, IoT 기반의 에듀테크 스타트업',
      txt05: '기타 교육 시장을 혁신할 수 있는 스타트업',
      txt06: '담당 액셀러레이터 배정을 통한 밀착 지원 제공',
      txt07: '사업협력 프로젝트(교육과정, 서비스 개발 등)의 기획 및 사업화 지원',
      txt08: '드림플러스의 글로벌 진출지원 프로그램인 ‘GEP(Global Expansion Program)’을 통한 해외 진출 기회 제공',
      txt09: '드림플러스 강남의 ‘디지털 미디어 센터’ 를 활용할 수 있는 기회 제공',
      txt10: '법무 및 홍보 서비스 지원',
      txt11: '한화(한화투자증권, 한화자산운용) 및 교원(교원인베스트먼트)그룹은 물론 외부 VC들을 통한 투자검토 기회 제공',
      txt12: '한화그룹 및 교원그룹의 사업인프라 연계 네트워킹 지원',
      txt13: '국내외 교육업계 전문가 네트워킹 지원',
    },
    FAndB: {
      txt01: '<드림플러스X장진우 F&B창업 아카데미>는 성공적인 창업 및 사업가 육성 특화 프로그램입니다.<br> 성공적인 요식사업의 꿈을 가진 창업가들을 위해 아이디어 기획, 스토리텔링, 브랜딩, 네이밍, 아이덴티티, 인테리어, 사운드, 세무/법률, 서비스, 홍보 등 총 10가지의 주제로 교육이 진행됩니다. <span class="point">각 분야의 성공적인 멘토들의 특강을 들을 수 있는 기회도 함께 제공하고 있습니다.</span>',
      txt02: '요식업 창업의 꿈을 갖고 있는 예비 사업가',
      txt03: '아이디어, 스토리텔링, 브랜딩, 네이밍, 아이덴티티, 인테리어 사운드, 세무 및 법률 지식, 서비스, 홍보 관련 특강/본강의 형태로 수업 진행',
      txt04: '한화 그룹 또는 계열사 보유 공간 내 입점 기회 제공',
      txt05: 'Global Expansion Program을 통한 해외진출 기회 제공',
      txt06: '모집한 수강료를 통해 ㈜장진우 내에서 우수 선별 업체에 투자 기회 제공',
      txt07: '그룹 내 Gourmet 494, 호텔&리조트(FC사업부) 등 연계 지원',
      txt08: '(선착순 50명 선발)',
    },
    Contents: {
      txt01: '<드림플러스 트래블 콘텐츠 프로그램>은 여행을 테마로 진행되는 대학생 인턴십 프로그램입니다. 콘텐츠 크리에이터 영역의 청년취업 지원 CSR 활동으로 운영됩니다.<br> 국내여행 무상지원, 콘텐츠 제작 지원 및 멘토(야놀자, 어반플레이, 윤지민, 장진우 등)의 특강은 물론 <span class="point">한화그룹 입사지원시 서류전형 면제의 혜택이 주어집니다.</span>',
      txt02: '국내/외 대학교 재(휴)학생 1~3학년 (전공무관)',
      txt03: '평소 여행을 즐기고, 좋아하며, 여행 관련 콘텐츠 제작에 관심이 많은 대학생',
      txt04: '로컬의 숨은 매력과 새로운 여행코스 개발에 관심이 많은 대학생',
      txt05: '다양한 형식의 디지털 콘텐츠 (영상, 블로그, 카드뉴스 등) 제작이나 디자인(상품 디자인, 포토샵 등) 능력을 갖춘 대학생',
      txt06: '기업 마케팅용 디지털 콘텐츠 실무(기획-제작-유통)를 경험해보고 싶은 대학생',
      txt07: '아이디어, 스토리텔링, 브랜딩, 네이밍, 아이덴티티, 인테리어 사운드, 세무 및 법률 지식, 서비스, 홍보 관련 특강/본강의 형태로 수업 진행',
      txt08: '한화리조트가 위치한 지역 중 컨텐츠 소스가 풍부한 지역을 중심으로 4박5일 여행 기회 제공',
      txt09: '드림플러스 강남의 ‘디지털미디어센터’를 활용해 컨텐츠 제작 기회 부여',
      txt10: '서류 및 포트폴리오 접수',
      txt11: '1차 서류 및 포트폴리오 심사',
      txt12: '콘텐츠 크리에이터 프로그램 홍보 영상',
    },
    Fashion: {
      txt01: '<드림플러스 패션&굿즈 액셀러레이팅 프로그램>은 디자이너의 꿈을 실현 할 수 있도록 “패션&굿즈”영역으로 지원 분야를 확대하여 창업을 지원하는 프로그램입니다. 6개월간 전문 액셀러레이터의 밀착 지원 및 생산과 유통 등 창업 관련 교육이 제공됩니다. 또한 <span class="point">해외 진출의 기회와 IP(특허, 상표권 등), 법무, 홍보 및 VC들을 통한 투자 검토 기회를 제공</span>합니다.',
      txt02: '라이프스타일(패션, 굿즈)에 적용 가능한 자신만의 디자인 아이디어를 보유한 개인 또는 팀',
      txt03: '대학(원)생, 그래픽 디자이너, 일러스트레이터, 캐릭터 디자이너 등',
      txt04: '커스텀 디자이너 브랜드 창업을 희망하는 예비 사업가',
      txt05: '6개월간 전문 액셀러레이터 밀착 지원',
      txt06: '창업 관련 교육 프로그램',
      txt07: '제품 생산/유통/마케팅 특화 교육, 산업 트렌드 등',
      txt08: '전문가 멘토링 지원',
      txt09: 'E-Comerce MD(티몬, GS샵 등), 패션 전문 기업 및 분야 전문가 등',
      txt10: '사업협력 프로젝트(상품 개발 및 유통 채널 확보 등)의 기획 및 사업화 지원',
      txt11: '드림플러스의 글로벌 진출지원 프로그램인 ‘GEP(Global Expansion Program)’을 통한 해외 진출 기회 제공',
      txt12: '드림플러스 강남의 ‘디지털 미디어 센터’를 활용할 수 있는 기회 제공',
      txt13: '법무(상표권, IP 등) 및 홍보 서비스 지원',
      txt14: '한화 그룹 및 패션&굿즈 관련 기업은 물론, 외부 VC를 통한 투자 검토 기회 제공',
      txt15: '한화 그룹 및 유통 대기업과의 사업 인프라 연계 네트워킹 지원',
      txt16: '국내외 패션&굿즈 업계 전문가 네트워킹 지원 ',
      txt17: '패션&굿즈 프로그램 소개 및 커스텀 장비 참조 영상',
    },
  },
  Corporate: {
    txt01: '기업의 개방형 혁신(Open Innovation)을 보다 빠르고 효율적으로 추진할 수 있도록 지원합니다.',
    txt02: '기업형 액셀러레이팅',
    txt03: '<드림플러스 기업형 액셀러레이팅 프로그램>은 기업들이 개방형 혁신(Open Innovation)을 보다 빠르고 효율적으로 추진할 수 있도록 지원하는 프로그램입니다.<br> 파트너사의 목적에 맞는 특화된 프로그램의 설계 및 스타트업 모집과 육성에 걸친 액셀러레이팅 운영 전 과정을 지원하고 있습니다. 참여 기업들은 드림플러스의 노하우, 국내외 네트워크 및 우수 스타트업 풀을 지원받음으로써 독자적으로 액셀러레이팅 프로그램 운영시보다 빠르고 성공적으로 <span class="point">미래산업 에코시스템에 안착</span>할 수 있습니다.',
    txt04: '단기간 내 ACC 역량 내재화',
    txt05: '국내 기업형 ACC의 선두 주자로 초기 투자 유치 선호기업 1위로 선정된 드림플러스가 보유한 정보와 노하우를 바탕으로, 파트너사의 니즈와 특성을 반영하여 맞춤형 ACC 프로그램 제공',
    txt06: '임차료 절감',
    txt07: '동일 규모의 공간 사용 시 단독 운영 대비 임차료(보증금, 인테리어비 포함) 절감 가능',
    txt08: '다양한 부대 시설(이벤트홀, 미디어센터 등) 이용 혜택 제공',
    txt09: '유망 Startup sourcing 및 사업 협력 기회 발굴',
    txt10: 'Open Innovation 니즈에 부합하는 스타트업 발굴을 위한 스타트업 모집공고의 심사, 최종 선발까지 전 프로세스 지원',
    txt11: '드림플러스의 커뮤니티 내 타기업, 입주 스타트업 등과의 협력 기회 창출 가능',
    txt12: '기업<span>(파트너스)</span>',
    txt13: '스타트업 액셀러레이팅 프로그램 의뢰',
    txt14: '드림플러스 강남 내 액셀러레이팅 공간 임차',
    txt15: '드림<span>플러스</span>',
    txt16: '기업 특화 액셀러레이팅 프로그램 설계',
    txt17: '스타트업 모집 공고',
    txt18: '선발<span>스타트업</span>',
    txt19: '드림플러스 강남 사무공간 입주',
    txt20: '기업 액셀러레이팅 프로그램 참여',
    txt21: '신규 사업 협력 및 투자기회 모색',
    txt22: '이랜드는 유통 및 패션 분야의 전문 기업으로,',
    txt23: '오픈 이노베이션을 위해 드림 플러스와 함께 빅데이터, 이미지 인식, 상품 추천 등 분야별 스타트업을 발굴하여 6개월간 보육하며 이랜드몰의 신규 사업과 협력하고 투자를 진행하였습니다.',
    txt24: '(’18. 7월 ~ ’19. 1월)',
    txt25: '현대자동차그룹',
    txt26: '이랜드',
    txt27: '공개모집<br/>(11월 25일 ~ 12월 22일)',
    txt28: '서류심사 결과발표<br/>(1월 중)',
    txt29: '대면심사<br/>(1월 중)',
    txt30: '최종 결과 발표<br/>(1월 중)',
    txt31: '프로그램 시작<br/>(2월 중)',
  },
  Gep: {
    txt01: '해외 진출을 목표로 하는 스타트업을 대상으로 충남창조경제혁신센터와 함께 약 3개월간 현지 사업화를 지원합니다.',
    txt02: '해외사업화 액셀러레이팅',
    txt03: '<드림플러스 GEP프로그램>은 해외 진출을 목표로 하는 스타트업을 대상으로 충남창조경제혁신센터와 함께 약 3개월간 현지 사업화를 지원하는 프로그램입니다.<br> 선정된 기업은 한화 드림플러스와 현지 액셀러레이터를 통해 로컬 파트너사 등 <span class="point">네트워크 발굴 · 연계를 지원</span>하며, 이와 함께 출장에 따른 체재비 및 필요시 <span class="point">현지 사무공간을 지원</span>하고 있습니다.',
    txt04: '현지 출장 시 체재비 및 항공료',
    txt05: '2,000,000원 범위 내 식사, 숙박, 항공료 등 실비지원',
    txt06: '현지 오피스 공간 제공',
    txt07: '중국, 베트남',
    txt08: 'Open Innovation 니즈에 부합하는 스타트업 발굴을 위한 스타트업 모집공고에서 심사, 최종 선발까지 모든 프로세스를 지원',
    txt09: '드림플러스 강남의 커뮤니티 내 타기업, 입주 스타트업 등과의 협력 기회 창출 가능',
  },
  Tenant: {
    Gangnam: {
      txt01: '드림플러스에 입주한 입주사를 위해 특화된 액셀러레이팅 프로그램을 운영합니다.',
      txt02: '드림플러스 강남',
      txt03: '드림플러스 63 핀테크센터',
      txt04: '액셀러레이팅 프로그램',
      txt05: '입주사가 빠르게 성장해 나갈 수 있도록 <span class="point">드림플러스만의 인프라와 네트워크를 활용한 다양한 액셀러레이팅 프로그램을 운영 · 지원합니다.</span><br> 입주사의 성장을 최고의 가치로 대내외 사업연계와 투자지원 등을 통해 남다른 성장 전략을 모색할 수 있는 스타트업 생태계 허브의 역할을 하고자 합니다.',
      txt06: '입주 후 입주사 ACC 담당자에게 개별 요청',
      txt07: '이벤트 페이지를 통해 신청',
      txt08: '입주사의 사업분야와 관련된 기업들과 사업연계, 제휴를 통해 성장을 지원하고 투자사 연계를 통한 투자유치를 지원합니다.<br> 드림플러스 입주사간 네트워킹 및 협업을 지원하여 서로를 통해 성장해 나갈 수 있는 기회를 제공합니다.',
      txt09: '법무, 회계, 특허법인 등 다양한 파트너들을 통한 세일즈, 인사, 노무, 사업개발, 법률 등에 대한 전문가 컨설팅 및 이용 우대혜택을 제공하는 프로그램입니다',
      txt10: '그룹 채널 ‘드림플러스 포털’  및 다양한 채널을 통한 홍보, 마케팅 서비스를 제공합니다.',
      txt11: '블록체인, 핀테크 등의 금융분야는 물론 헬스케어, 교육, 컨텐츠 등 라이프스타일 전반에 관한 특정 주제를 선정하여 전문가 교육 및 지식공유 프로그램입니다.',
      txt12: '입주사 및 파트너사들을 초청하여 정기적으로 주최하는 네트워킹으로 입주사들이 가볍게 참여할 수 있는 프로그램입니다.',
      txt13: '스타트업 등 각 분야의 셀럽, 전문가들과 가벼운 식사 또는 티타임을 통해 네트워크, 인사이트 등을 얻을 수 있는 프로그램입니다.',
    },
    Square: {
      txt01: '인큐베이팅 프로그램',
      txt02: '핀테크 전 분야/보험산업과의 시너지를 창출할 수 있는 모든 스타트업에게 <span class="point">사무공간 무상제공 외 다양한 성장지원 프로그램을 제공합니다.</span>',
      txt03: '모집 시 별도 공고',
      txt04: '63빌딩 6F 드림플러스 63 핀테크센터 사무공간을 무상으로 제공합니다.',
      txt05: '법률, IP, 세무/회계, 홍보 등 전문가 연계와 해외진출 지원 및 AWS 크레딧, 애플코리아 제품 할인 구매 등을 지원합니다.',
      txt06: '담당 액셀러레이터 배정을 통한 밀착지원과 국내 유수 파트너사와의 사업협력 및 네트워킹을 지원합니다.',
      txt07: '국내 및 해외 협력 투자자를 통한 다양한 투자유치 기회를 지원합니다.',
    },
  },
  Academy: {
    txt01: '빠르게 변하는 기술과 TREND에 발맞춘 드림플러스만의 온/오프라인 교육컨텐츠를 제공합니다.',
    txt02: 'DREAMPLUS 블록체인 아카데미',
    txt03: '6주에 끝나는 블록체인의 모든 것, 개론부터 개발까지 언제, 어디서나, 무료로 쉽게 공부하는 블록체인 ‘Blockchain at Berkeley\'의 유명 강의를 한국어 자막과 함께, 강사진과 직접 소통하세요!',
    txt04: '수강 대상 및 혜택',
    txt05: '비트코인, 블록체인 기술과',
    txt05_1: '파생 비즈니스가',
    txt05_2: '궁금하신 분들을 위한 코스',
    txt06: '블록체인 개발자가 되고 싶으신분을 위한',
    txt06_1: 'A to Z 강의',
    txt07: '온라인 수강자들을 위한 Offline events와',
    txt07_1: '개발자를 위한 Bootcamp가 예정되어 있습니다.',
    txt08: '암호학과 경제학',
    txt09: '비트코인과 블록체인 기술',
    txt10: '블록체인 비즈니스',
    txt11: '블록체인 프로토콜과 합의 & 비트코인의 역사',
    txt12: '비트코인 매커니즘과 마이닝',
    txt13: '이더리움과 스마트계약 & 게임이론',
    txt14: '암호경제학과 합의 매커니즘',
    txt15: '블록체인의 응용과 확장성',
    txt16: '알트코인과 블록체인 플랫폼의 미래',
    txt17: 'Solidity 언어 학습',
    txt18: '스마트계약 개발',
    txt19: 'dApps과 Web3.0',
    txt20: '이더리움 메커니즘과 스마트계약',
    txt21: 'Solidity와 스마트계약 보안',
    txt22: '토큰과 스마트계약 아키텍처 & Web3',
    txt23: 'Web 3.0과 IPFS',
    txt24: 'Urbit 프로젝트',
    txt25: '라이트닝 네트워크 & 코스모스',
    txt26: '강사소개',
    txt27: 'Blockchain at Berkeley는 美 UC버클리 대학의 학회로 미국 대학 중 블록체인을 가장 먼저 도입하였습니다. 재학생 뿐만 아니라 졸업생, blockchain 현업 종사자 등으로 구성되어 있으며, 2015년 설립 이후 샌프란시스코의 블록체인 생태계 형성에 기여하고 있습니다.',
    txt28: 'Blockchain at Berkeley는 블록체인 교육, 기업 컨설팅, R&D 분야를 중점적으로 진행하며 사업영역을 넓히고 있습니다. ExxonMobil, Qualcomm 등의 기업 프로젝트에서 블록체인 컨설팅을 실행한 바 있으며, 블록체인 교육 분야에서는 글로벌 최고 수준의 강의를 선보이고 있습니다.',
    txt29: '수강신청',
    txt30: '블록체인 아카데미',
    txt31: '드림플러스 트레블 크리에이터',
  },
  Scaleup: {
    txt01: '<span class=\'hanwa-tit\'></span>계열사 협업 프로그램',
  },
  academy: {
    Common: {
      txt01: '지원하기',
      txt02: '회사정보',
      txt03: '멤버정보',
      txt04: '대표서비스',
      txt05: '투자내용',
      txt06: '기타입력',
      txt07: '지원서확인',
      txt08: '는 필수입력 항목입니다.',
      txt09: '액셀러레이팅 프로그램 지원하기',
      txt10: '대표자 연락처',
      txt11: '지원내역보기',
      txt12: '지원서 항목 입력',
    },
    blockchain: {
      txt01: '6주에 끝나는 블록체인의 모든 것, 개론부터 개발까지',
      txt02: '언제, 어디서나, 무료로 쉽게 공부하는 블록체인',
      txt03: '‘Blockchain at Berkeley\'의 유명 강의를 한국어 자막과 함께, 강사진과 직접 소통하세요!',
      txt04: `6주에 끝나는 블록체인의 모든 것, 개론부터 개발까지 언제, 어디서나, 무료로 쉽게 공부하는 블록체인
      ‘Blockchain at Berkeley'의 유명 강의를 한국어 자막과 함께, 강사진과 직접 소통하세요!`,
      txt05: '블록체인에 대한 이해도를 높이고',
      txt06: '전문 개발자를 양성하기 위한 프로그램으로,',
      txt07: '누구나 무료로 온라인 강좌를 진행하실 수 있습니다.',
      txt08: '강의 진행중',
      txt09: 'dApp 개발 입문과 실습',
      txt10: '이 프로그램에서는 블록체인 프로토콜과 그 기능들을 배우고,',
      txt11: '모피어스랩 플랫폼을 통해 이더리움 dApp을 개발하고 테스트해 볼 수 있습니다.',
      txt12: '이 프로그램에서는 블록체인 프로토콜과 그 기능들을 배우고, 모피어스랩 플랫폼을 통해 이더리움 dApp을 개발하고 테스트해 볼 수 있습니다.',
      txt13: '코스별 타이틀 및 소개',
      txt14: '블록체인, 그리고 분산원장 기술에 대하여',
      txt15: '블록체인 기술의 핵심을 알아보는 내용으로서, 블록체인이 무엇이고 합의알고리즘이 어떻게 작동하는지에 대해 배우게 됩니다.',
      txt16: '다양한 프로토콜들에 대한 기능적 이해와 블록체인의 사용 사례',
      txt17: '다양한 프로토콜들을 분석하고 각 프로토콜의 대표적인 케이스들을 통해 블록체인이 실생활에 어떻게 적용될 수 있는지에 대하여 살펴봅니다.',
      txt18: '이더리움 스마트컨트렉트 개발 및 테스트',
      txt19: '솔리디티는 이더리움 개발에 있어 가장 널리 사용되는 프로그래밍 언어입니다. 스마트컨트렉트는 프로그래밍의 논리적 단계에 따라 시행되며 이더리움의 암호화를 통해 보호됩니다.',
      txt20: 'Node.js 또는 Spring Boot 를 사용한 웹 앱 개발 및 테스트',
      txt21: '웹은 현대 사회에 엄청난 가치를 선사한 오픈소스 프로젝트입니다. 웹 앱을 생성하고 인터넷을 통해 배포하는 것은 스마트 분산 앱을 개발하는데 있어 핵심 요소입니다.',
      txt22: '개발한 웹 앱과 스마트컨트렉트의 연동',
      txt23: '스마트컨트렉트는 전세계 수천 개의 노드에 분산된 시스템에 존재합니다. 그들에게 접근하고 상호작용을 하는 방법은 간단하고 직관적이어야 그 잠재력을 발견할 수 있습니다.',
      txt24: 'dApp 배포와 이후 단계에 대하여 (객관식 퀴즈 포함)',
      txt25: '최종 배포 전, 앱의 안정성 확보는 필수적 요소로서, 강점과 약점을 잘 파악해야 합니다. 또한, 마지막 주 수업에는 코스 전반적인 내용을 테스트 해볼 수 있는 퀴즈를 제공합니다.',
      txt26: '이더리움 dApp과 모피어스랩 개발 플랫폼 입문',
      txt27: '이더리움 dApp 개발 실전을 준비하는 과정입니다. 모피어스랩에서 제공하는 클라우드 개발 환경과 개발 도구들을 알아봅니다.',
      txt28: '블록체인 아카데미와 관련한 기타 문의는',
      txt29: '으로 해주세요',
      txt30: 'Morpheus Labs 강의 Point',
      txt31: '다양한 프로토콜',
      txt32: '분산화 기술의 개념 및',
      txt33: '여러 블록체인 프로토콜의',
      txt34: '기능적 이해',
      txt35: '간편한 개발환경 제공',
      txt36: '별도 노드의 생성 없이',
      txt37: 'dApp 구동이 가능한 클라우드',
      txt38: '환경 제공',
      txt39: 'dApp 개발 실습',
      txt40: '직접 dApp을 개발하고',
      txt41: '테스트해보는 실습 프로그램',
      txt42: 'Morpheus Labs 강의 커리큘럼',
      txt43: '블록체인, 그리고 분산원장 기술에 대하여',
      txt44: '다양한 프로토콜들에 대한 기능적 이해와 블록체인의 사용사례',
      txt45: '이더리움 dApp과 모피어스랩 개발 플랫폼 입문',
      txt46: '이더리움 스마트컨트렉트 개발 및 테스트',
      txt47: 'Node.js 또는 Spring Boot 를 사용한 웹 앱 개발 및 테스트',
      txt48: '개발한 웹 앱과 스마트컨트렉트의 연동',
      txt49: 'dApp 배포와 이후 단계에 대하여',
      txt50: 'Morpheus Labs 회사소개',
      txt51: '모피어스랩은 블록체인 개발자와 기업들의 분산화 어플리케이션 개발,',
      txt52: '테스트, 그리고 실행을 돕기 위해 설계된 블록체인 서비스 플랫폼입니다.',
      txt53: '이용자는 모피어스랩을 통해 앱 라이브러리 및 테스트를 위한',
      txt54: '안전한 실험실과 같은 공동개발 환경을 제공받게 됩니다.',
      txt55: '블록체인 어플리케이션 개발과 테스트에 소요되는 시간과 비용을 획기적으로',
      txt56: '줄이고 좀 더 생산적인 일에 집중할 수 있도록 모피어스랩이 돕겠습니다.',
      txt57: '모피어스랩은 블록체인 개발자와 기업들의 분산화 어플리케이션 개발,테스트, 그리고 실행을 돕기 위해 설계된 블록체인 서비스 플랫폼입니다. 이용자는 모피어스랩을 통해 앱 라이브러리 및 테스트를 위한 안전한 실험실과 같은 공동개발 환경을 제공받게 됩니다. 블록체인 어플리케이션 개발과 테스트에 소요되는 시간과 비용을 획기적으로 줄이고 좀 더 생산적인 일에 집중할 수 있도록 모피어스랩이 돕겠습니다.',
      txt58: 'R3 Corda 핵심 개념',
      txt59: 'Corda는 상호 신뢰하지 못하는 참가자들로 하여금, 서로 계약을 기록하고 관리하는 오픈소스 블록체인 플랫폼입니다.',
      txt60: 'Corda는 R3와 세계 우수의 금융회사가 함께 만들었습니다.',
      txt61: '다양한 산업 및 금융 감독기구의 기준에 부합하고 기밀성과 호환성이 뛰어난 블록체인 기술입니다.',
      txt61_1: 'Corda는 상호 신뢰하지 못하는 참가자들로 하여금, 서로 계약을 기록하고 관리하는 오픈소스 블록체인 플랫폼입니다. Corda는 R3와 세계 우수의 금융회사가 함께 만들었습니다. 다양한 산업 및 금융 감독기구의 기준에 부합하고 기밀성과 호환성이 뛰어난 블록체인 기술입니다.',
      txt62: '코스별 타이틀 및 소개',
      txt63: 'Corda Setup (코다 셋업)',
      txt64: '윈도우 환경과 맥 환경에서의 코다 셋업 방법을 배우게 됩니다.',
      txt65: 'The Corda Ledger (코다 원장)',
      txt66: '코다 원장(Corda Ledger) 및 코다 네트워크에 대한 전반적인 소개와 네트워크 안에서',
      txt66_1: ' 어떻게 데이터가 서로 공유되는지를 배우게 됩니다.',
      txt66_2: ' 코다 원장(Corda Ledger) 및 코다 네트워크에 대한 전반적인 소개와 네트워크 안에서 어떻게 데이터가 서로 공유되는지를 배우게 됩니다.',
      txt67: 'Corda States (코다 상태)',
      txt68: '거래 시, 거래 당사자들이 갖게 되는 비가역적인 데이터의 State 개념에 대해 배우게 됩니다.',
      txt68_1: '',
      txt68_2: '거래 시, 거래 당사자들이 갖게 되는 비가역적인 데이터의 State 개념에 대해 배우게 됩니다.',
      txt69: 'Transactions (거래)',
      txt70: '원장을 업데이트 하는 거래(Transaction)에 대해서 배우고, Corda 의 거래 방식과 다른',
      txt70_1: '분산원장의 거래 방식의 차이에 대해서 배우게 됩니다.',
      txt70_2: '원장을 업데이트 하는 거래(Transaction)에 대해서 배우고, Corda 의 거래 방식과 다른 분산원장의 거래 방식의 차이에 대해서 배우게 됩니다.',
      txt71: 'Contracts (계약)',
      txt72: '계약 코드(Contract Code)에 따라 원장에 기록된 상태값이 어떻게 전이(evolution)되는지',
      txt72_1: '배우게 됩니다.',
      txt72_2: '계약 코드(Contract Code)에 따라 원장에 기록된 상태값이 어떻게 전이(evolution)되는지 배우게 됩니다.',
      txt73: 'Commands (커맨드)',
      txt74: '거래의 의도를 파악할 수 있게 하는 커맨드(Command)에 대해서 배우게 됩니다.',
      txt75: 'Timewindows (타임윈도우)',
      txt76: '거래가 일어나게 되는 타임윈도우(Timewindow)에 대해서 배우게 됩니다.',
      txt76_1: '',
      txt76_2: '거래가 일어나게 되는 타임윈도우(Timewindow)에 대해서 배우게 됩니다.',
      txt77: 'Attachments (첨부)',
      txt78: 'Corda 거래에 첨부될 수 있는 Attachments에 대해서 배우게 됩니다.',
      txt78_1: '',
      txt78_2: 'Corda 거래에 첨부될 수 있는 Attachments에 대해서 배우게 됩니다.',
      txt79: 'Flows (플로우)',
      txt80: 'Corda 플로우(Flow) 개념에 대해서 배우게 됩니다.',
      txt80_1: '',
      txt80_2: 'Corda 플로우(Flow) 개념에 대해서 배우게 됩니다.',
      txt81: 'Consensus (합의)',
      txt82: 'Corda 네트워크에서 어떻게 합의(Consensus)를 도출하게 되는지 배우게 됩니다.',
      txt83: 'Notaries (노터리)',
      txt84: '이중 지불 방지를 하는 역할을 하는 노터리(Notary)에 대한 개념을 배우게 됩니다.',
      txt84_1: '',
      txt84_2: '이중 지불 방지를 하는 역할을 하는 노터리(Notary)에 대한 개념을 배우게 됩니다.',
      txt85: 'Oracles (오라클)',
      txt86: '스마트 컨트랙트에 필요한 외부 정보를 제공하는 오라클(Oracle)에 대한 개념을 배우게 됩니다.',
      txt86_1: '',
      txt86_2: '스마트 컨트랙트에 필요한 외부 정보를 제공하는 오라클(Oracle)에 대한 개념을 배우게 됩니다.',
      txt87: 'Corda node, CorDapps and Network (코다노드, 코댑, 네트워크)',
      txt88: 'Corda 노드, 코댑(CorDapp), Corda 네트워크가 어떻게 구성되어 있는지를 배우게 됩니다.',
      txt88_1: '',
      txt88_2: 'Corda 노드, 코댑(CorDapp), Corda 네트워크가 어떻게 구성되어 있는지를 배우게 됩니다.',
      txt89: 'Ontology 블록체인 dApp 개발',
      txt90: 'Ontology 블록체인 개발 코스의 목적은 비기너들이 dApp을 개발하는 방법을 빠르게 숙달할 수 있도록 하는 것 입니다. ',
      txt91: '개발자 가이드 / Solo-chain / Ontology Cli / Smartx 및 Vscode 확장 / Punica / SDK 및 dAPI / Cyano / 시그니처 서비스 등',
      txt92: '총 10편의 개발 튜토리얼 영상으로 이루어졌습니다.',
      txt93: 'Ontology 블록체인 개발 코스의 목적은 비기너들이 dApp을 개발하는 방법을 빠르게 숙달할 수 있도록 하는 것 입니다. 개발자 가이드 / Solo-chain / Ontology Cli / Smartx 및 Vscode 확장 / Punica / SDK 및 dAPI / Cyano / 시그니처 서비스 등 총 10편의 개발 튜토리얼 영상으로 이루어졌습니다.',
      txt94: 'Ontology 강의 Point',
      txt95: '개발 가이드',
      txt96: 'Ontology dApp',
      txt96_1: '개발 도구 사용 방법 소개',
      txt97: '스마트컨트랙트개발',
      txt97_1: '도구',
      txt98: '비기너에게 도움이 되는 Ontology',
      txt99: '스마트컨트랙트 개발 소프트웨어',
      txt100: 'Smartx와 vscode 확장 도구',
      txt101: 'Ontology 기반',
      txt101_1: 'dApp 개발에 필수적인',
      txt102: 'Ontology dApp',
      txt102_1: '개발 프레임워크',
      txt103: 'Ontology',
      txt103_1: '강의 커리큘럼',
      txt103_2: 'Ontology 강의 커리큘럼',
      txt104: 'Ontology 공식 Develop document 소개',
      txt105: 'solo chain 사용 가이드',
      txt105_1: 'cyano wallet 사용 가이드',
      txt105_2: 'dapi 사용 가이드',
      txt105_3: 'Rpc/Restful/Websocket Api 사용 가이드',
      txt105_4: 'CLI(1) 사용 가이드',
      txt105_5: 'VSCode Extension 사용 가이드',
      txt105_6: 'CLI(2) 사용 가이드',
      txt105_7: 'Ontology Signature Service 사용 가이드',
      txt105_8: 'Punica Suite 사용 가이드',
      txt106: 'Ontology',
      txt106_1: '회사소개',
      txt106_2: 'Ontology 회사소개',
      txt107: '온톨로지는 차세대 블록체인 프로젝트이며, 분산 신뢰 협력 플랫폼입니다.',
      txt108: '온톨로지는 분산 원장과 스마트 컨트랙트 시스템을 포함하는 새로운 고성능 퍼블릭 블록체인 프로젝트이자, 분산된 신뢰를 기반으로 한 협력 플랫폼입니다.',
      txt109: '온톨로지는 퍼블릭 블록체인을 지원하는 프레임워크로, 다양한 프로토콜을 통해 여러 어플리케이션이 사용하고 있는 다양한 퍼블릭 블록체인에 적용할 수 있습니다.',
      txt110: '또한, 분산 네트워크에서 가능한 여러 시나리오에서 모두 사용될 수 있도록 분산된 디지털 신원 프레임워크와 분산된 데이터 거래 프로토콜 등 기본 인프라 수준에서 적용될 수 있는 공통적인 모듈을 제공합니다. 나아가, 실제로 어플리케이션이 온톨로지에 적용되었을 때의 시나리오에 따라 계속해서 새로운 공통 모듈을 제공할 예정입니다.',
      txt111: '코스별 타이틀 및 소개',
      txt112: 'Ontology 공식 Develop document 소개',
      txt113: 'Ontology 공식',
      txt114: 'Develop document 소개',
      txt115: 'Ontology란 무엇인가?',
      txt116: 'Ontology 설치 방법',
      txt117: 'Ontology 스마트컨트랙트',
      txt118: '인터페이스',
      txt119: '디지털 아이덴티티와 클레임',
      txt120: 'SDKs 개요',
      txt121: 'Wallet 통합',
      txt122: 'dAPI 사용 가이드',
      txt123: 'Solo-chain 사용 가이드',
      txt124: 'Solo-chain (Ontology 단일 노드 프라이빗 망) 란 무엇인가?',
      txt125: 'Solo-chain (Ontology 단일 노드',
      txt126: ' 프라이빗 망) 란 무엇인가?',
      txt127: 'Solo-chain 설치 방법',
      txt128: 'Solo-chain 데모를 위한 단계별 가이드',
      txt129: 'Cyano Wallet 사용 가이드',
      txt130: 'Cyano wallet이란 무엇인가?',
      txt131: 'Cyano wallet 설치 방법',
      txt132: 'Cyano wallet 사용 가이드',
      txt133: '새로운 ONT ID 생성하기',
      txt134: 'dAPI 공급자인 Cyano Wallet 프로세스 차트',
      txt135: 'SmartX와 함께 Cyano wallet을 사용하는 단계별 가이드',
      txt136: 'dAPI 사용 가이드',
      txt137: 'dAPI란 무엇인가?',
      txt138: 'OEP-6 아키텍처',
      txt139: 'dAPI 설치와 사용에 대한 단계별 가이드',
      txt140: 'dAPI 방법에 대한 예시',
      txt141: '데모 프로젝트 실행하기',
      txt142: 'Rpc/Restful/Websocket API 사용 가이드',
      txt142_1: 'Rpc/Restful/Websocket API',
      txt142_2: '사용 가이드',
      txt143: 'Ontology-API 란 무엇인가?',
      txt144: 'restful API in SmartX에서 restful API 사용 방법',
      txt145: 'Python SDK에서 Rpc API 사용 방법',
      txt146: 'Ontology CLI 사용 가이드(1)',
      txt147: 'Ontology CLI는 무엇인가?',
      txt148: 'Ontology 노드 시작과 관리하기',
      txt149: '지갑(wallet) 관리 방법',
      txt150: '자산(asset) 관리 방법',
      txt151: 'VSCode Extension 사용 가이드',
      txt152: 'Ontology VSCode Extension은 무엇인가?',
      txt153: 'VSCode Extension설정 방법',
      txt154: 'Ontology 확장 사용 가이드',
      txt155: '추가 사용 팁',
      txt156: 'Ontology CLI 사용 가이드(2)',
      txt157: '블록체인의 쿼리 정보',
      txt158: '스마트컨트랙트 구축 및 실행',
      txt159: '거래의 구축, 서명, 전송 방법',
      txt160: 'Ontology Signature Service 사용 가이드',
      txt160_1: 'Ontology Signature Service',
      txt160_2: '사용 가이드',
      txt161: 'Ontology Signature Service 란 무엇인가?',
      txt162: 'Signature Service 설치 방법',
      txt163: 'Signature Service 사용 가이드',
      txt164: 'API 구성',
      txt165: 'API methods 사용 가이드',
      txt166: 'Punica Suite 사용 가이드',
      txt167: 'Punica suite란 무엇인가?',
      txt168: 'Punica suite 설치 방법',
      txt169: 'Punica suite로 신규 프로젝트 실행',
      txt170: 'Corda 핵심',
      txt171: '개념 이해',
      txt172: 'CorDapps',
      txt173: '구현',
      txt174: 'Corda 개발자',
      txt175: '입문',
      txt175_1: 'R3 강의 Point',
      txt176: 'R3 강의 커리큘럼',
      txt177: 'Corda Setup (코다 셋업)',
      txt178: '윈도우 환경과 맥 환경에서의 코다 셋업 방법을 배우게 됩니다.',
      txt179: 'The Corda Ledger (코다 원장)',
      txt180: '코다 원장(Corda Ledger) 및 코다 네트워크에 대한 전반적인 소개와 네트워크 안에서 어떻게 데이터가 서로 공유되는지를 배우게 됩니다.',
      txt181: 'Corda States (코다 상태)',
      txt182: '거래 시, 거래 당사자들이 갖게 되는 비가역적인 데이터의 State 개념에 대해 배우게 됩니다.',
      txt183: 'Transactions (거래)',
      txt184: '원장을 업데이트 하는 거래(Transaction)에 대해서 배우고, Corda 의 거래 방식과 다른 분산원장의 거래 방식의 차이에 대해서 배우게 됩니다.',
      txt185: 'Contracts (계약)',
      txt186: '계약 코드(Contract Code)에 따라 원장에 기록된 상태값이 어떻게 전이(evolution)되는지 배우게 됩니다.',
      txt187: 'Commands (커맨드)',
      txt188: '거래의 의도를 파악할 수 있게 하는 커맨드(Command)에 대해서 배우게 됩니다.',
      txt189: 'Timewindows (타임윈도우)',
      txt190: '거래가 일어나게 되는 타임윈도우(Timewindow)에 대해서 배우게 됩니다.',
      txt191: 'Attachments (첨부)',
      txt192: 'Corda 거래에 첨부될 수 있는 Attachments에 대해서 배우게 됩니다.',
      txt193: 'Flows (플로우)',
      txt194: 'Corda 플로우(Flow) 개념에 대해서 배우게 됩니다.',
      txt195: 'Consensus (합의)',
      txt196: 'Corda 네트워크에서 어떻게 합의(Consensus)를 도출하게 되는지 배우게 됩니다.',
      txt197: 'Notaries (노터리)',
      txt198: '이중 지불 방지를 하는 역할을 하는 노터리(Notary)에 대한 개념을 배우게 됩니다.',
      txt199: 'Oracles (오라클)',
      txt200: '스마트 컨트랙트에 필요한 외부 정보를 제공하는 오라클(Oracle)에 대한 개념을 배우게 됩니다.',
      txt201: 'Corda node, CorDapps',
      txt202: 'and Network',
      txt203: '(코다노드, 코댑, 네트워크)',
      txt204: 'Corda node, CorDapps and Network',
      txt205: '(코다노드, 코댑, 네트워크)',
      txt206: 'Corda 노드, 코댑(CorDapp), Corda 네트워크가 어떻게 구성되어 있는지를 배우게 됩니다.',
      txt207: 'R3 회사 소개',
      txt208: 'R3는 블록체인 Corda를 만드는 엔터프라이즈 소프트웨어 회사입니다.',
      txt209: 'R3의 비전은 거래 참가자들이 직접 상호 거래를 함에 있어,',
      txt210: '비효율성이 없어지고 기밀성 및 호환성이 보장되는 것입니다.',
      txt211: '세상은 상호 비즈니스 파트너들간에 완벽한 synchrony 하에 운영되며,',
      txt212: '이중적이고 부합하지 않은 기록들은 더 이상 존재하지 않을 것 입니다.',
      txt213: 'R3는 블록체인 Corda를 만드는 엔터프라이즈 소프트웨어 회사입니다. R3의 비전은 거래 참가자들이 직접 상호 거래를 함에 있어, 비효율성이 없어지고 기밀성 및 호환성이 보장되는 것입니다. 세상은 상호 비즈니스 파트너들간에 완벽한 synchrony 하에 운영되며, 이중적이고 부합하지 않은 기록들은 더 이상 존재하지 않을 것 입니다.',
      txt214: '강의소개',
      txt215: '강사',
      txt215_1: '클리프 안, 질리안 추, 그리핀 하스킨스, 브라이언 호, 알렉산더 트란 (Blockchain at Berkeley)',
      txt216: '강의 형태',
      txt216_1: '온라인 강의 (주 강의 2회 권장, 6주 완성 코스)',
      txt217: '대상',
      txt217_1: '암호학, 블록체인, 암호화폐에 관심이 있는 누구나',
      txt218: '교재',
      txt218_1: '아래 학습자료는 온라인에서 무료 열람할 수 있습니다.',
      txt219: '커리큘럼',
      txt220: '주차',
      txt221: '개요 : 프로토콜과 합의',
      txt222: '비트코인의 역사',
      txt223: '비트코인 메커니즘과 최적화',
      txt224: '비트코인 IRL:',
      txt224_1: '지갑, 마이닝(채굴) 외',
      txt224_2: '비트코인 IRL : 지갑, 마이닝(채굴) 외',
      txt225: '이더리움과 스마트컨트랙트 :',
      txt225_1: '분산화된 미래를 가능하게 하다',
      txt225_2: '이더리움과 스마트컨트랙트 : 분산화된 미래를 가능하게 하다',
      txt226: '게임이론과 네트워크 공격 :',
      txt226_1: '비트코인을 어떻게 공격할 것인가',
      txt226_2: '게임이론과 네트워크 공격 : 비트코인을 어떻게 공격할 것인가',
      txt227: '암호경제학과 지분 증명(Proof-of-Stake)',
      txt228: '대체 합의 메커니즘',
      txt229: '기업과 블록체인 :',
      txt229_1: '실세계 응용',
      txt229_2: '기업과 블록체인 : 실세계 응용',
      txt230: '블록체인 확장성 :',
      txt230_1: '대중을 위한 암호화폐',
      txt230_2: '블록체인 확장성 : 대중을 위한 암호화폐',
      txt231: '익명성 :',
      txt231_1: '믹싱과 알트코인',
      txt231_2: '익명성 : 믹싱과 알트코인',
      txt232: '고급 주제 :',
      txt232_1: 'The People\'s Pick',
      txt232_2: '고급 주제 : The People\'s Pick',
      txt233: '아래 두 가지 주제 중 하나를 골라 Piazza에서 토론해 보세요.',
      txt234: 'dApp (분산앱) 아이디어나 블록체인 사례에 대해 논해보세요 최대한 창의적이고 상세하게 논의하고, 블록체인을 사용해야하는 이유를 정당화해보세요',
      txt235: '작업증명 Take a side:',
      txt235_1: '작업증명(Proof-of-Work)나 지분증명(Proof-of-Stake) 둘 중 자신이 선호하는 알고리즘과 그 이유를 서술해보세요. 혹은 그밖에 다른 알고리즘을 선호한다면, 이를 소개해주세요(합의 프로토콜의 장점/단점, 사용하는 암호화폐, 다른 합의 프로토콜과의 차이점 등)',
      txt236: '확장성 솔루션 중 한가지 혹은 그 외 솔루션을 선택하여 장/단점을 논의해보세요.',
      txt237: '자신만의 암호화폐나 블록체인 플랫폼을 디자인해보세요. 자신의 디자인을 정당화하거나 이미 존재하는 암호화폐/블록체인 플랫폼의 특성을 분석해보세요.',
      txt238: '선수과목은 없으나 블록체인 개론 수업인 Blockchain Fundamental 수강을 추천드립니다.',
      txt239: '기타',
      txt240: 'Stanford University의 특별 강좌(2개)가 있을 예정입니다.',
      txt241: '온라인 강의 수강, 읽기 자료, 과제/프로젝트(Piazza 제출)',
    },
    CompanyInfo: {
      txt01: '업체명',
      txt02: '업체명 국문 입력',
      txt03: '업체명 영문 입력',
      txt04: '사업자 등록번호',
      txt05: '-없이 사업자 등록번호 입력',
      txt06: '법인 등록번호',
      txt07: '-없이 법인 등록번호 입력',
      txt08: '벤처기업 인증번호',
      txt09: '-없이 벤처기업 인증번호 입력',
      txt10: '설립 연도',
      txt11: '년 입력',
      txt12: '월 선택',
      txt13: '일 선택',
      txt14: '대표자(이름)',
      txt15: '대표자명 입력',
      txt16: '공동 대표',
      txt17: '공동 대표명 입력',
      txt18: '대표자는 {0}명까지 입력 가능합니다.',
      txt19: '현재 직원 수',
      txt20: '직원 수 입력',
      txt21: '직원 추가 계획',
      txt22: '추가 계획 직원 수 입력',
      txt23: '입주 후 6개월 이내에 예상되는 추가 인력을 입력해 주세요.',
      txt24: '홈페이지 / SNS ',
      txt25: '홈페이지 URL 입력',
      txt26: 'SNS URL 입력',
      txt27: '페이스북 URL 입력',
      txt28: '수상 경력',
      txt29: '날짜',
      txt30: '대회명 입력',
      txt31: '주최 기관 입력',
      txt32: '수상 내역 입력',
      txt33: '최근 수상 경력부터 입력해 주세요.',
      txt34: '보유 지적 재산권',
      txt35: '명칭 입력',
      txt36: '증명서 번호 입력',
      txt37: '등록',
      txt38: '출원',
      txt39: '등록/출원 날짜',
      txt40: '보유 허가 및 등록 현황',
      txt41: '허가 명칭 입력',
      txt42: '허가 번호 입력',
      txt43: '신청 구분',
      txt44: '멤버 구성',
      txt45: '개인 사업자',
      txt46: '예비 창업자',
      txt47: '개인',
      txt48: '팀',
      txt49: '팀 인원 숫자만 입력',
      txt50: '대표자 이메일',
      txt50_1: '대표자 이메일 입력',
      txt51: '대표자 최종 학력',
      txt51_1: '대표자 최종 학력 입력',
      txt52: '대표자 주요 이력',
      txt52_1: '대표자 주요 이력 입력',
      txt53: '{0}자 까지 입력 가능합니다.',
      txt54: '-없이 숫자만 입력',
      txt55: '미국 법인 설립 여부',
      txt56: '예',
      txt57: '아니오',
    },
    MemberInfo: {
      txt01: '멤버',
      txt02: '이름',
      txt03: '풀타임',
      txt04: '파트타임',
      txt05: '역활',
      txt06: '생년월일',
      txt07: '창업 프로그램 참여경험',
      txt08: '시작일',
      txt09: '종료일',
      txt10: '프로그램',
      txt11: '수상',
      txt12: '수여 혜택',
      txt13: '최근 참여 경험부터 입력해 주세요.',
      txt14: '멘토',
      txt15: '멘토 이름',
      txt16: '멘토 주요 이력',
      txt17: '휴대폰 번호',
      txt18: '이메일',
      txt19: '소속',
      txt20: '-없이 휴대폰 번호 입력',
      txt21: '남',
      txt22: '여',
      txt23: '성별',
      txt24: '개발자',
      txt25: '기획자',
      txt26: '디자이너',
      txt27: 'MBTI',
      txt28: '보유 기술 스택',
      txt29: '프로그래밍 활용 능력',
      txt30: '백신 접종 여부',
    },
    ServiceInfo: {
      txt01: '서비스명',
      txt02: '서비스명 입력',
      txt03: '서비스 소개',
      txt04: '서비스 소개 입력',
      txt05: '{0}자까지 입력 가능합니다.',
      txt06: '서비스 차별점',
      txt07: '서비스 차별점 입력 (200자 까지)',
      txt08: '서비스/채널 URL',
      txt09: '서비스/채널 URL 입력',
      txt10: 'SNS URL',
      txt11: '홍보영상/기사 URL',
      txt12: '홍보영상/기사 URL 입력',
    },
    InvestmentInfo: {
      txt01: '투자 유치 현황',
      txt02: '투자 일자',
      txt03: '투자 기관',
      txt04: '투자 금액 (억단위)',
      txt05: '라운드',
      txt06: '최근 유치 현황부터 입력해 주세요.',
      txt07: '최소 지원 자격은 기존 투자 유무와 관계없이 지원 시점에서 법인 설립이 완료된 기업입니다.',
      txt08: '투자 이력 없음',
    },
    OtherInfo: {
      txt01: '파일첨부',
      txt02: '사업자 등록증 파일 첨부',
      txt03: '주주 명부 파일 첨부',
      txt04: '등기부등본 파일 첨부',
      txt05: '벤처기업 등록증 파일 첨부',
      txt06: '지적재산권 사본 파일 첨부',
      txt07: '원하는 지원 항목',
      txt08: '법무 지원(각종 법률검토, 특허출원 등)',
      txt09: '세무/회계',
      txt10: '홍보/마케팅',
      txt11: '개발 검수/지원',
      txt12: '해외 진출(아시아 위주)',
      txt13: '금융사 연계 등의 사업 지원',
      txt14: 'BM 강화',
      txt15: '투자 유치',
      txt16: '사무 공간',
      txt17: '지원 동기 및 포부',
      txt18: '지원 포부 입력(최대 1000자)',
      txt19: '입력 (최대 1000자)',
      txt20: '프로그램 참가 서약서',
      txt21: '서약서에 동의합니다.',
      txt22: '지원 영역',
      txt23: '첨부 파일 용량은 10MB 이하로 등록해 주세요.',
      txt24: '리테일테크',
      txt25: '신규 리테일 컨텐츠',
      txt26: '신규 비즈니스 모델',
      txt27: '원하는 지원 항목 선택',
      txt28: '중국',
      txt29: '베트남',
      txt30: '일본',
      txt31: '인도네시아',
      txt32: 'HEALTH MONITORING',
      txt33: 'HEALTH DATA ANALYTICS',
      txt34: 'IN-CAR CARE SOLUTION',
      txt35: 'CONNECTED CARE SERVICE',
      txt36: '운전자 감정/건강 정보 수집',
      txt37: '외부 환경 정보 수집',
      txt38: '식단 분석',
      txt39: '영양소 분석',
      txt40: 'HEART RATE',
      txt41: 'BODY MASS',
      txt42: '혈당 측정/분석',
      txt43: '수면 분석',
      txt44: '건강 테라피 콘텐츠',
      txt45: '심리 상담 및 케어 기능',
      txt46: '감정 상태 맞춤형 실내 환경',
      txt47: '장애 보조 실내 기능',
      txt48: '운동/자세 보조 기능',
      txt49: '병원/약국 접근성',
      txt50: '맞춤형 명소 추천/안내',
      txt51: '보험 연계',
      txt52: '지원 경로',
      txt53: '지원 경로 선택',
      txt54: 'SNS (Facebook, Instagram, YouTube)',
      txt55: '스타트업 매체 (벤처스퀘어, 온오프믹스, 스타트업위클리, 이벤터스, 센서블박스, 코리아스타트업포럼 등)',
      txt56: '오프라인 포스터 및 드림플러스 홈페이지',
      txt57: '외부 추천',
      txt58: '기타',
      txt59: '외부 추천사',
      txt590: '공모전 사이트(씽굿 등)',
      txt60: '기타 지원경로',
      txt61: '컨텐츠 비즈니스',
      txt62: '신규 플랫폼',
      txt63: 'Digital Healthcare',
      txt64: 'Wellness',
      txt65: '현대자동차그룹 및 한화 계열사 현업 팀과의 희망 협업 프로젝트를 선택하여 지원 (테마는 1개 선택, 프로젝트는 복수 선택 가능)',
      txt66: '인지기능 스크리닝',
      txt67: '인지기능 트레이닝',
      txt68: '식이요법',
      txt69: '운동요법',
      txt70: 'NFT',
      txt71: '핀테크',
      txt72: '결제',
      txt73: '콘텐츠(IP)',
      txt74: 'DeFi',
      txt75: 'GAME',
      txt76: '물류',
      txt77: '기타',
      txt78: 'DAO',
      txt79: 'SECURITY',
    },

    DisplayInfo: {
      txt01: '년',
      txt02: '월',
      txt03: '일',
      txt04: '명',
      txt05: '주요 멘토',
      txt06: '(억)',
      txt07: '기타',
      txt08: '참가서약서 동의',
      txt09: '프로그램 참가 서약서 동의함',
      txt10: '프로그램 참가 서약서 동의하지 않음',
      txt11: '마케팅 활용 동의',
      txt12: '마케팅 활용 동의함',
      txt13: '마케팅 활용 동의하지 않음',
      txt14: 'Yes',
      txt15: 'No',
      txt16: 'Yes, I do',
    },
    ApplyComplete: {
      txt01: '지원이 완료되었습니다.',
      txt02: '정상적으로 지원서가 접수되었습니다.',
      txt03: '지원 결과는 공모 지원 내역에서 확인할 수 있습니다.',
      txt04: '확인',
      txt05: '기존에 지원 완료된 데이터가 있습니다.<br>중복 지원은 불가능합니다.',
    },
    ApplyTemporary: {
      txt01: '임시 저장된 지원서 내용을 불러오시겠습니까?',
      txt02: '임시 저장된 프로그램 지원하기 등록 요청을 완료한 후,',
      txt03: '새로운 프로그램 지원이 가능합니다.',
      txt04: '새로 작성',
    },
  },
  Kind: {
    AC101: 'Fintech',
    AC102: 'Lifestyle-Digital Healthcare / Wellness',
    AC103: 'Lifestyle-Education',
    AC104: 'Lifestyle-F&B',
    AC105: 'Academy-Travel Contents Creator',
    AC106: 'Lifestyle-Fashion & Goods Creator',
    AC107: 'Corporate ACC-Eland',
    AC108: 'GEP',
    AC109: 'Tenant ACC-Gangnam',
    AC110: 'Tenant ACC-63',
    AC111: 'Lifestyle-Digital Contents',
    AC112: 'Lifestyle-Digital Contents Creator',
    AC113: 'ScaleUp',
    AC114: 'Corporate ACC-Hyundai',
    AC115: 'Corporate ACC-Eisai',
  },
};
